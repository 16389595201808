import { useState } from 'react';

import Modal from '../UI/Modal';
import Header from '../components/Header';
import Home from '../components/Home';
import Arbol from '../components/Arbol';
import loading from '../images/loading.gif';
import '../styles/styles.scss';

const ArbolContainer = () => {
  const [isValidated, setIsValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [arbolList, setArbolList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [volverAValidar, setVolverAValidar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => {
    setCurrentUser(null);
    setShowModal(true);
  }
  const closeModal = () => setShowModal(false);
  const handleAddToArbol = (value, index) => {
    if (index !== null) {
      arbolList[index] = value;
    } else {
      arbolList.push(value);
    }
    setArbolList(arbolList);
    setShowModal(false);
    setCurrentUser(null);
  }

  const resetArbol = () => {
    setArbolList([]);
    setIsValidated(false);
  }

  const editClick = (userIdx) => {
    setCurrentUser({ ...arbolList[userIdx], idx: userIdx });
    setShowModal(true);
  }

  return (
    <>
      <div className={`container ${isLoading ? 'isLoading' : ''}`}>
        {isLoading && <img className='loading-img' src={loading} alt='loading' />}
        <Header />
        {arbolList.length === 0 && <Home openModal={openModal} />}
        {arbolList.length > 0 && (
          <Arbol 
            openModal={openModal} 
            users={arbolList.map((user, index) => ({ ...user, idx: index }))} 
            reset={resetArbol}
            editClick={editClick}
            isValidated={isValidated}
            setIsValidated={setIsValidated}
            volverAValidar={volverAValidar}
            setVolverAValidar={setVolverAValidar}
            setIsLoading={setIsLoading}
          />
        )}
      </div>
      <Modal 
        isOpen={showModal}
        onClose={closeModal}
        setValue={handleAddToArbol}
        userCount={arbolList.length}
        currentUser={currentUser}
        arbolList={arbolList}
        lastUser={arbolList[arbolList.length - 1]}
        isValidated={isValidated}
        setVolverAValidar={setVolverAValidar}
      />
    </>
  );
};

export default ArbolContainer;