import Header from '../components/Header';
import Creators from '../components/Creators';

const Faq = () => (
    <div className='container faq-section'>
        <Header showBack />
        <h2>FAQ</h2>
        <p>Preguntas y respuestas sobre las principales dudas que pueden surgir.</p>
        <p><span className='question'>Cuando decimos ‘via judicial’ es lo que comunmente se conoce como ‘juicio por via materna’. Juicio por falta de turnos es otra cosa y en esta herramienta no nos enfocamos en eso.</span></p>
        <span className='question'>¿Cuándo corresponde tramitar mi ciudadanía por ‘via judicial’?</span>
        <p>Cuando el hijo (hombre o mujer) de la primer mujer en nuestro árbol haya nacido antes del 1ro de Enero del año 1948.</p>
        <span className='question'>¿Esta herramienta es infalible?</span>
        <p className='no-margin-bottom'>Y la verdad que no. La armamos en base a la experiencia real propia y de casos de conocidos. Pero los árboles familiares y los casos pueden ser muchos y muy diferentes.</p>
        <p>La idea es ir mejorando la herramienta para ayudarnos entre todos.</p>
        <span className='question'>¿Cuál es el limite de generaciones que puedo subir? (abuelo, bisabuelo, tatarabuelo, etc)</span>
        <p>Si el ancestro italiano falleció antes del 17/03/1861 ahi no podés solicitar el reconocimiento de la ciudadanía italiana. Pero más allá de esto no habría limitantes de generaciones.</p>
        <span className='question'>Tips en base a casos conocidos:</span>
        <ul>
            <li>Tu familiar italiano no debe haber renunciado a la ciudadanía italiana. Sino no vas a poder reclamar tu ciudadanía por descendencia.</li>
            <li>A veces creemos que el mejor camino es ir por ejemplo por nuestra bisabuela italiana (mujer), pero no tenemos en cuenta que podríamos subir una generación más, y comenzar nuestro árbol con el padre (italiano) de nuestra bisabuela. Esto podría convertir tu caso en ‘via administrativa’ y ahorrarte el juicio.</li>
        </ul>
        <p>Por cualquier duda, sugerencia, idea, crítica (aceptamos constructivas para ir mejorando la plataforma día a día y ayudar a más gente), podés escribirnos a <a href='mailto:info@siamodentro.com'>info@siamodentro.com</a></p>
        <Creators />
    </div>
);

export default Faq;